import React, { useState } from "react";
import styled, { css } from "react-emotion/macro";
import { Link, navigate } from "@reach/router";
import logo from "./img/creative_land_logo.png";
import { ReactComponent as UserIcon } from "./img/user_icon.svg";
import { colors } from "../../colors";
import { useTranslation } from "react-i18next/hooks";
import {
  Menu,
  MenuList,
  MenuButton,
  MenuLink,
  MenuItem
} from "@reach/menu-button";
import "./dropdownButton.css";

const Navbar = props => {
  const [t, i18n] = useTranslation();
  const lng = i18n.language.slice(0, 2);
  const { location } = props;
  const [alternativeLangs, setAlternativeLangs] = useState([]);
  const [langMessage, setLangMessage] = useState("");
  const currentTranslations = ["es", "en"]; // CHANGE IN THE FUTURE SO IS AUTOMATIC
  const getAlternativeLangs = () => {
    //VERY MESSY!! CHANGE IN THE FUTURE
    // When you change language the old one disappears, so we have to reload all of them
    i18n.loadLanguages(currentTranslations, () => {
      const pathArray = location.pathname.split("/").slice(2);
      const currentLanguage = lng.slice(0, 2); // In case is a language like es-ES...
      // HANDLE BASE ROUTES LIKE HOME, ABOUT US...
      if (pathArray.length <= 1) {
        const currentRoute = pathArray[0]; // Only one string in the array
        // It's a base route, we handle it with the base dictionary
        i18n.reloadResources(); // IS IT REALLY NECESSARY??
        // We get the current route's key for translation
        const currentRoutesDictionary =
          i18n.store.data[currentLanguage].translation.routes;
        let routeKey = Object.keys(currentRoutesDictionary).find(
          key => currentRoutesDictionary[key] === currentRoute
        ); // Search object key by value
        if (pathArray.length === 0) {
          routeKey = "home";
        }
        // We get all languages that have the same route with they language name and route value
        const alternativeLangsHolder = [];
        currentTranslations.forEach(function(lang) {
          if (lang !== currentLanguage) {
            const langRouteKey =
              i18n.store.data[lang].translation.routes[routeKey];
            alternativeLangsHolder.push({
              lang: lang,
              route: `/${lang}/${langRouteKey}`
            });
          }
        });
        if (alternativeLangs.length === 0) {
          setLangMessage("No more translations :(");
        } else {
          setLangMessage("");
        }
        setAlternativeLangs(alternativeLangsHolder);
      } else {
        // HANDLE DYNAMIC ROUTES. TODO IN THE FUTURE!!
        return;
      }
    });
  };
  return (
    <Container>
      <LeftSide>
        <Link to={`${lng}/${t("routes.home")}`}>
          <img src={logo} alt="Creative Land" />
        </Link>
        <Nav>
          <NavLink to={`${lng}/${t("routes.home")}`}>{t("nav.home")}</NavLink>
          <NavLink to={`${lng}/${t("routes.about")}`}>{t("nav.about")}</NavLink>
          <NavLink to={`${lng}/${t("routes.challenges")}`}>
            {t("nav.challenges")}
          </NavLink>
          <NavLink to={`${lng}/${t("routes.contact")}`}>
            {t("nav.contact")}
          </NavLink>
        </Nav>
      </LeftSide>
      <RightSide>
        <Menu>
          <MenuButton>
            <UserIcon alt="user" />
          </MenuButton>
          <MenuList>
            <MenuLink to={`${lng}/${t("routes.register")}`}>
              {t("nav.register")}
            </MenuLink>
            <MenuLink to={`${lng}/${t("routes.login")}`}>
              {t("nav.login")}
            </MenuLink>
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton onClick={getAlternativeLangs} className={langMenu}>
            {lng.slice(0, 2).toUpperCase()}
          </MenuButton>
          <MenuList>
            {alternativeLangs.length === 0 ? (
              <MenuItem onSelect={() => console.log("sorry")}>
                {langMessage}
              </MenuItem>
            ) : (
              alternativeLangs.map(function(langRoute) {
                return (
                  <MenuItem
                    onSelect={() => {
                      i18n.changeLanguage(langRoute.lang);
                      navigate(langRoute.route);
                    }}
                    key={langRoute.route}
                  >
                    {langRoute["lang"].toUpperCase()}
                  </MenuItem>
                );
              })
            )}
          </MenuList>
        </Menu>
      </RightSide>
    </Container>
  );
};
export default Navbar;
/////STYLES/////
const NavLink = props => (
  // Needed for styling the links
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        style: {
          color: isCurrent ? "black" : colors.negroRoto,
          fontWeight: isCurrent ? 700 : 500
        }
      };
    }}
  />
);

const Container = styled("div")`
  z-index: 200;
  background-color: ${colors.blanco};
  border-bottom: 2px solid #555555;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  padding-top: 0.1em;
  padding-left: 0.1em;
  padding-right: 0.2em;
  font-size: 1em;
  height: 2em;
  a {
    text-decoration: none;
    white-space: nowrap;
  }
  @media (min-width: 500px) {
    font-size: 1.2em;
  }
`;
Container.displayName = "NavBarContainer";

const LeftSide = styled("div")`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  flex: 0 1 90%;
  overflow-x: auto;
  a {
    display: flex;
    padding: 0.2em;
    img {
      height: 1.3em;
    }
  }
`;
LeftSide.displayName = "LeftSide";
const Nav = styled("nav")`
  display: flex;
  flex-flow: row nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  mask-image: linear-gradient(
    to right,
    transparent,
    black 10px,
    black 97%,
    transparent
  );
  padding-left: 0.3em;
  a {
    padding-right: 0.5em;
  }
`;
Nav.displayName = "Nav";

const RightSide = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-right: 0.2em;
`;
RightSide.displayName = "RightSide";

const langMenu = css`
  margin-top: 0.1em;
  background-color: ${colors.gris};
  border-radius: 3px;
  border: 1px solid ${colors.negroRoto};
  font-weight: bold;
`;
