import React, { lazy, Suspense, useEffect } from "react";
import { Router, Location } from "@reach/router";
import styled from "react-emotion/macro";
import { colors } from "./colors";
import { useTranslation } from "react-i18next/hooks";
import Navbar from "./components/NAVBAR";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga";
const HomePage = lazy(() => import("./pages/HomePage"));
const AboutPage = lazy(() => import("./pages/AboutPage"));
const ChallengesPage = lazy(() => import("./pages/ChallengesPage"));
const ContactPage = lazy(() => import("./pages/ContactPage"));
const RegisterPage = lazy(() => import("./pages/RegisterPage"));
const LoginPage = lazy(() => import("./pages/LoginPage"));
const RecoverPage = lazy(() => import("./pages/RecoverPage"));
const NotFoundPage = lazy(() => import("./pages/NotFoundPage"));

// GOOGLE ANALYTICS
ReactGA.initialize("UA-114354023-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const App = props => {
  //eslint-disable-next-line
  const [t, i18n] = useTranslation();
  useEffect(
    () => {
      document.documentElement.setAttribute("lang", i18n.language);
    },
    [i18n.language]
  );
  return (
    <Container>
      <Location>
        {({ location }) => {
          return <Navbar location={location} />;
        }}
      </Location>
      <Suspense fallback={<div />}>
        <Router>
          <HomePage path="/" />
          <HomePage path={`/:lang/${t("routes.home")}`} />
          <AboutPage path={`/:lang/${t("routes.about")}`} />
          <ChallengesPage path={`/:lang/${t("routes.challenges")}`} />
          <ContactPage path={`/:lang/${t("routes.contact")}`} />
          <RegisterPage path={`/:lang/${t("routes.register")}`} />
          <LoginPage path={`/:lang/${t("routes.login")}`} />
          <RecoverPage path={`/:lang/${t("routes.recover")}`} />
          <NotFoundPage default />
        </Router>
      </Suspense>
      <CookieConsent
        style={{ background: "#16a085" }}
        buttonText={t("gdpr.button_message")}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      >
        {t("gdpr.message")}
      </CookieConsent>
    </Container>
  );
};

const Container = styled("div")`
  background-color: ${colors.azulOscuro};
  display: flex;
  flex-flow: column nowrap;
  border-top: 4px solid #c0392b;
`;
Container.displayName = "AppContainer";

export default App;
